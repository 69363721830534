import { api } from "./api";

interface ChatResponse {
  answer: string;
  sessionId: string;
}

/**
 * Gets an answer from the vertex chat service.
 * @param query - The question the user posts.
 * @param documentId - The document of the investor.
 * @param documentIdsAccessible - Documents the chat service has access to.
 * @param dueDiligenceCaseId - Startup ID
 * @param previousConversation - Previous query/question, if any
 * @returns The answer received from the vertex chat.
 * @throws Error if the request fails.
 */
export const getChatAnswer = async (
  query: string,
  documentId: number,
  documentIdsAccessible: number[],
  dueDiligenceCaseId: number,
  previousConversation: string,
  investorCollaborationId: number,
  sessionId?: string
): Promise<ChatResponse> => {
  try {
    let requestObj = {
      query,
      documentId,
      documentIdsAccessible,
      dueDiligenceCaseId,
      previousConversation,
      investorCollaborationId,
      sessionId,
    };
    const { data } = await api.post<ChatResponse>(`/api/vertex`, requestObj);
    return data;
  } catch (error) {
    console.error("Failed to get a chat response", error);
    throw new Error("Failed to get a chat response");
  }
};
