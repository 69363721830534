import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import SendIcon from "@mui/icons-material/Send";
import { keyframes } from "@mui/system";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { getChatAnswer } from "actions/chat";
import { usePitchDeck } from "context/PitchDeckContext";

const TypingIndicator = () => (
  <MDTypography
    variant="body2"
    sx={{
      display: "inline-block",
      fontSize: "44px",
      lineHeight: "1",
      "&::after": {
        content: '"..."',
        animation: `${blink} 1.4s infinite both`,
      },
    }}
  ></MDTypography>
);

const ChatWidget = () => {
  const [sendingMessage, setSendingMessage] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [previousChat, setPreviousChat] = useState({ query: "", answer: "", sessionId: "" });
  const { pitchResponse } = usePitchDeck();

  const toggleChatBox = () => setIsChatOpen((prev) => !prev);

  const handleSendMessage = async () => {
    setSendingMessage(true);
    const previousConversation = query;
    setPreviousChat({ ...previousChat, answer: "", query: query });

    const chatResponse = await getChatAnswer(
      query,
      pitchResponse?.investorCollaboration?.documentId,
      pitchResponse?.investorCollaboration?.documentIdsAccessible,
      pitchResponse?.investorCollaboration?.dueDiligenceCaseId,
      previousConversation,
      pitchResponse?.investorCollaboration?.id,
      previousChat?.sessionId
    );

    setPreviousChat({ answer: chatResponse?.answer, query, sessionId: chatResponse?.sessionId });
    setQuery("");
    setSendingMessage(false);
  };

  return (
    <>
      <MDButton sx={chatButtonStyle} color="primary" onClick={toggleChatBox}>
        <Icon sx={chatIconStyle}>{isChatOpen ? "close" : "chat"}</Icon>
        {!isChatOpen ? "Talk to the Dataroom" : "Close"}
      </MDButton>
      {isChatOpen && (
        <Box sx={chatBoxStyle}>
          <MDBox display="flex" flexDirection="column">
            <MDBox
              display="flex"
              flexDirection="column"
              sx={{
                ...chatDisplayAreaStyle,
                height: (previousChat.query || previousChat.answer) && 300,
              }}
            >
              <MDBox sx={previousChat.query && userChatBubbleStyle} alignSelf="end">
                <MDTypography variant="body2">{previousChat.query}</MDTypography>
              </MDBox>
              <MDBox mt={2} width="90%" alignSelf="start">
                <MDTypography variant="body2">
                  {sendingMessage ? (
                    <TypingIndicator />
                  ) : (
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {`${previousChat?.answer}`}
                    </ReactMarkdown>
                  )}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox sx={inputWithButtonWrapper}>
              <MDInput
                sx={chatInputStyle}
                label="Message Bummock AI..."
                multiline
                value={query}
                onChange={(e: any) => setQuery(e.target.value)}
              />
              <MDBox sx={sendButton}>
                <IconButton onClick={handleSendMessage} disabled={!query}>
                  <SendIcon />
                </IconButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Box>
      )}
    </>
  );
};

export default ChatWidget;

const chatButtonStyle = {
  position: "fixed",
  bottom: 20,
  right: 25,
  height: "50px",
  zIndex: 1000,
  width: "220px",
};

const chatIconAnimation = keyframes`
  50% { opacity: 0.3 }
`;

const chatIconStyle = {
  animation: `${chatIconAnimation} 5s infinite`,
};

const chatBoxStyle = {
  position: "fixed",
  bottom: 80,
  right: 25,
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 3,
  borderRadius: 2,
  p: 2,
  zIndex: 999,
};

const chatDisplayAreaStyle = {
  overflowY: "auto",
  padding: "0 15px 15px 15px",
  scrollbarWidth: "thin",
  scrollbarColor: "#ccc #fff",
};

const userChatBubbleStyle = {
  backgroundColor: "#f0f0f0",
  borderRadius: 5,
  padding: "5px 15px",
  margin: "10px 0",
};

const inputWithButtonWrapper = {
  maxWidth: "100%",
  position: "relative",
};

const chatInputStyle = {
  border: "2px solid white",
  borderRadius: "10px",
  width: "100%",
};

const sendButton = {
  position: "absolute",
  right: 10,
  bottom: 5,
  marginLeft: 10,
};

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;
