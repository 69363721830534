/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// PitchDeckPage page components
import { usePitchDeck } from "context/PitchDeckContext";
import ChatWidget from "containers/chat/ChatWidget";
import PDFViewer from "./components/PitchDeck";

const PitchDeckPage = () => {
  const [gettingPitchdeck, setGettingPitchdeck] = useState(false);
  const navigate = useNavigate();
  const { documentLink, loadPitchDeck } = usePitchDeck();
  const { linkHash } = useParams<{ linkHash: string; investorEmail: string }>();

  const hashRegex = /^[a-fA-F0-9]{40}$/;
  const isNotHashedLink = !hashRegex.test(linkHash);

  const investorEmail = localStorage.getItem("bummock-investor-email");

  const getPitchDeck = async () => {
    setGettingPitchdeck(true);
    await loadPitchDeck(linkHash, investorEmail);
    setGettingPitchdeck(false);
  };

  useEffect(() => {
    if (!documentLink) {
      if (isNotHashedLink) {
        navigate("/sign-in");
      } else {
        getPitchDeck();
      }
    }
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        {documentLink ? (
          <Card sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <PDFViewer presignedURL={documentLink} />
            </MDBox>
          </Card>
        ) : (
          <h3>Page does not exist</h3>
        )}
        <ChatWidget />
      </MDBox>
    </DashboardLayout>
  );
};

export default PitchDeckPage;
