import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { sendEmail } from "actions/api";
import { usePitchDeck } from "context/PitchDeckContext";
import MDBox from "components/MDBox";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px",
  boxShadow: 24,
  p: 4,
};

const buttonRow = {
  mt: "15px",
  display: "flex",
  justifyContent: "center",
};

const DataroomModal = () => {
  const [open, setOpen] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const navigate = useNavigate();
  const { pitchResponse } = usePitchDeck();
  const linkHash = pitchResponse?.investorCollaboration?.investorShareableLink?.linkHash;

  const companyName = pitchResponse?.companyName;
  const companyNamePoss = companyName
    ? /s$/i.test(companyName)
      ? companyName
      : `${companyName}'s`
    : "The company";

  const handleSendDataroomAccessEmail = async () => {
    setSendingEmail(true);
    const startupUserEmail = pitchResponse.investorCollaboration.sharedByEmail;
    const investorEmail = pitchResponse.investorCollaboration.investor.email;
    let emailPayload = {
      to: startupUserEmail,
      subject: "Dataroom access request",
      text: `Hello,\n\nAn investor with the email ${investorEmail} has requested access to your dataroom on https://bummock.retool.com/.`,
      html: `
          <html>
            <body>
              <p>Hello,</p>
              <p>An investor with the email ${investorEmail} has requested access to your dataroom on <a href="https://bummock.retool.com/" target="_blank">Bummock AI</a>.</p>
              <p>Thank you,</p>
              <p>The Bummock AI Team</p>
            </body>
          </html>
        `,
    };
    const emailResponse = await sendEmail(emailPayload);
    if (emailResponse.message) {
      setEmailSent(true);
    }
    setSendingEmail(false);
  };

  const triggerCloseActions = () => {
    navigate(`/pitch-deck/${linkHash}`);
    setEmailSent(false);
    setOpen(false);
  };

  const handleClose = (event: any, reason: any) => {
    if (reason === "backdropClick") {
      event.preventDefault();
    } else {
      triggerCloseActions();
    }
  };

  const handleCloseButton = () => {
    triggerCloseActions();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="dataroom-modal-title"
      aria-describedby="dataroom-modal-description"
    >
      <Box sx={modalStyle}>
        <MDBox>
          <IconButton
            onClick={handleCloseButton}
            sx={{ position: "absolute", top: 8, right: 8, color: "gray" }}
          >
            <CloseIcon />
          </IconButton>
        </MDBox>
        {emailSent ? (
          <Typography id="dataroom-modal-description" sx={{ mt: 2 }}>
            {companyName} has received your request. Once granted, you will receive an email from
            Bummock AI to create an account and interact with {companyNamePoss} Dataroom.
          </Typography>
        ) : (
          <>
            <Typography id="dataroom-modal-description" sx={{ mt: 2 }}>
              Access is restricted.
            </Typography>
            <Typography id="dataroom-modal-description" sx={{ mt: 2 }}>
              In order to see the company&apos;s dataroom, you need to be invited by the company
            </Typography>
            <MDBox sx={buttonRow}>
              <MDButton
                color="primary"
                disabled={sendingEmail}
                onClick={handleSendDataroomAccessEmail}
              >
                Request Access
              </MDButton>
            </MDBox>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DataroomModal;
